<template>
  <div class="container mb-3">
    <h2 class="fw-bold">Avto İcbari Sığorta</h2>
  </div>

  <section id="cmtpl-insurance-form-section">
    <div class="container overflow-hidden">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item me-2">
          <button
            class="
              nav-link
              active
              bg-gray
              text-main
              insurance-nav-tabs-link
              font-size-small
              fw-bolder
              text-center
              active
              disabled
            "
            data-bs-toggle="pill"
            data-bs-target="#isb-frame-custom"
            type="button"
          >
            DİN NV,Resident
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="isb-frame-custom">
          <div class="col-lg-12 mt-5 px-3 position-relative">
            <div class="row">
              <div
                class="
                  col-lg-12
                  tab-content
                  border-blue
                  rounded
                  px-5
                  pb-5
                  pt-0
                  text-justify
                "
              >
                <div
                  class="row cmtpl-insurance-icons-container position-relative"
                >
                  <ul class="nav mx-auto pe-0 position-relative">
                    <li class="w-100 position-relative d-flex overflow-hidden">
                      <a
                        class="
                          insurance-form-icon
                          w-25
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          disabled
                        "
                        data-bs-toggle="pill"
                        href="#cmtpl-insurance-form"
                      >
                        <img src="@/assets/images/how-to-get-request.svg" />
                      </a>
                      <a
                        class="
                          insurance-form-icon
                          w-25
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          disabled
                        "
                        data-bs-toggle="pill"
                        href="#insurance-company"
                      >
                        <img src="@/assets/images/how-to-get-company.svg" />
                      </a>
                      <a
                        class="
                          insurance-form-icon
                          w-25
                          cursor-pointer
                          nav-link
                          mx-auto
                          text-center
                          position-relative
                          active
                          disabled
                        "
                        href="#payment"
                        data-bs-toggle="pill"
                      >
                        <img src="@/assets/images/how-to-get-coin.svg" />
                      </a>
                      <span class="slide-icon">Ödəniş</span>
                    </li>
                  </ul>
                </div>

                <div
                  id="cmtpl-insurance-form"
                  class="cmtpl-insurance-form insurance-form tab-pane"
                ></div>

                <div id="insurance-company" class="tab-pane"></div>

                <div id="payment" class="tab-pane active">
                  <payment :loading="isLoading" :data="contractData" :contractNumber="contractNumber" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <banner />
</template>

<script>
import Banner from "@/views/cmtpl/Banner.vue";
import Payment from "@/views/cmtpl/Payment.vue";

import api from "@/api/cmtpl.api";

export default {
  components: { Banner, Payment },
  data: () => ({
    isLoading: false,
    token: null,
    contractNumber: null,
    agentCode: null,
    contractData: null,
  }),
  methods: {
    async agentSuccess() {
      await api.agentSuccess(this.token, this.contractNumber, this.agentCode);
    },

    loadContract() {
      this.isLoading = true;

      api
        .getContractByNumber(this.contractNumber)
        .then((data) => {
          this.contractData = data;
        })
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },
  },
  async created() {
    this.token = this.$route.query.token ?? null;
    this.contractNumber = this.$route.query.contractNumber ?? null;
    this.agentCode = this.$route.query.agentCode ?? null;

    if (this.agentCode) {
      await this.agentSuccess();
    }

    this.loadContract();
  },
};
</script>