<template>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!loading">
    <div class="pb-2  col-xs-12 col-sm-12 col-md-12">
      <p class="h4 text-main  text-success check-info-head">
        Təbriklər!  Sizin şəhadətnaməniz uğurla buraxıldı! ({{ contractNumber }})
      </p>
    </div>
    <div v-if="data" class="row col-xs-12 col-sm-12 col-md-12">
      <div class="row check-info-content-body">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head">Sığorta şirkəti</div>
          <div class="check-info-content-body-text">
            {{ data.insuranceCompanyName }}
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head"> Müqavilə nömrəsi</div>
          <div id="carContract" class="check-info-content-body-text">
            {{ contractNumber }}
            <img src="@/assets/images/ins-copy.svg" @click="copyText('carContract')" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head">Sığorta haqqı</div>
          <div class="check-info-content-body-text">
            {{ data.price }}
            <span >AZN</span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
          <div class="check-info-content-body-head"> Ödəniş kodu</div>
          <div id="carPayment" class="check-info-content-body-text">
            {{ data.paymentNumber }}
            <img src="@/assets/images/ins-copy.svg" @click="copyText('carPayment')" />
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p class="payment-method">
          Ödəniş üşün aşağıdakı üsullardan birini seçin.
        </p>
        <div class="payments-column">
          <div class="col-xs-6 col-sm-6 col-lg-4 col-xl-4">
          <a href="https://gpp.az/WebPortal/Infosite" target="_blank">
              <div
              class="
                bg-gray
                text-center
                py-4
                rounded
                ins-payment-type-item
                cursor-pointer
              "
            >
              <img src="@/assets/images/e-gov.svg" />
            </div>
          </a>
        </div>
        <div class="col-xs-6 col-sm-6 col-lg-4 col-xl-4">
          <a href="https://www.million.az/services/insurance/ISB" target="_blank">
            <div
            class="
              bg-gray
              text-center
              py-4
              rounded
              ins-payment-type-item
              cursor-pointer
            "
            >
              <img src="@/assets/images/million.svg" />
            </div>
          </a>
        </div>
        <div class="col-xs-6 col-sm-6 col-lg-4 col-xl-4">
          <a href="https://expresspay.az/payment/service/980" target="_blank">
            <div
              class="
                bg-gray
                text-center
                py-4
                rounded
                ins-payment-type-item
                cursor-pointer
              "
            >
              <img src="@/assets/images/epay.svg" />
            </div>
          </a>
        </div>
        <div class="col-xs-6 col-sm-6 col-lg-4 col-xl-4">
          <a href="https://www.e-pul.az/epay/az/category/insurance/icbari_insurance" target="_blank">
            <div
            class="
              bg-gray
              text-center
              py-4
              rounded
              ins-payment-type-item
              cursor-pointer
            "
            >
              <img src="@/assets/images/epul.svg" />
            </div>
          </a>
        </div>
        </div>
      </div>

      <div v-if=homeURL class="row mt-5 px-0">
        <div class="d-flex justify-content-end pe-0">
          <a @click.prevent="goCabinet" class="btn-main btn-default btn-hover cursor-pointer d-inline-block ms-3">
            Kabinetə keçid et
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import authService from "@/services/auth.service";

export default {
  props: ["loading", "data","contractNumber"],
  data: () => ({
    homeURL:null,
  }),
  mounted() {
    this.homeURL =this.$route.path.startsWith("/cmtpl");
  },
  methods: {
    back() {
      this.$emit("back");
    },
    copyText(id) {
      var copyText = document.getElementById(id).textContent;
      navigator.clipboard.writeText(copyText);
    },
    goCabinet() {
      if (authService.isAuthenticated()) {
        this.$router.push("/profile");
      } else {
        this.emitter.emit("showLoginModal");
      }
    },
  },
};
</script>